/**
 *	common.js
 */
'use strict';

/* config
--------------------------------------------------*/
const APP = APP || {};  // グローバル変数格納用
APP.spBreakPoint = 800;
APP.tbBreakPoint = 1023;

// グローバル変数設定
APP.ww = window.innerWidth ? window.innerWidth: $(window).width();
APP.wh = window.innerHeight ? window.innerHeight: $(window).height();
APP.cww = APP.ww;  // リサイズ前のウインドウ幅を保持
APP.cwh = APP.wh;  // リサイズ前のウインドウ高を保持
APP.scrollTop = $(window).scrollTop();
APP.scrollBottom = APP.scrollTop + APP.wh;
APP.isSP = (APP.ww <= APP.spBreakPoint) ? true : false;
APP.noTap = false;

/* userAgent
--------------------------------------------------*/
import { _ua } from './component/ua';
if (_ua.Tablet) {
  $('body').addClass('tablet');
} else if (_ua.Mobile) {
  $('body').addClass('mobile');
  APP.isSP = true;
} else {
  $('body').addClass('is-pc');
}
if (_ua.iOS) {
  $('body').addClass('ios');
}
if (_ua.userAgent.indexOf('msie') != -1 || _ua.userAgent.indexOf('trident') != -1) {
  $('body').addClass('ie');
} else if(_ua.userAgent.indexOf('edge') != -1) {
  $('body').addClass('edge');
}

/* LOADED
--------------------------------------------------*/
$(window).on('load', function () {
  $('body').addClass('is-loaded');
  $('.loading').fadeOut(function() {
    $(this).remove();
  });
});

/* READY
--------------------------------------------------*/
$(function() {
  // # で始まるリンクにスムーススクロール
  APP.scSpeed = 400;
  function scrollHdl(_href) {
    let target = $(_href == '#' || _href == '' ? 'html' : _href);
    let position = target.offset().top;
    if (APP.isSP) {
      position -= 0;
      $('body').removeClass('is-block');
      $('.js-btn-nav').removeClass('is-open');
      $('.js-nav').fadeOut();
    }
    $('body,html').animate({scrollTop:position}, APP.scSpeed, 'swing');
  }
  $('a[href^="#"]').on('click', function (e) {
    e.preventDefault();
    let href= $(this).attr('href');
    if(href=='#') return false;
    if(href=='#pagetop'){
     $('html, body').animate({scrollTop: 0}, APP.scSpeed, 'swing');
     return false;
    }
    scrollHdl(href);
    return false;
  });
  // 遷移後のページ内リンク
  APP.URLhash = location.hash;
  setTimeout(function() {
    if (APP.URLhash) scrollHdl(APP.URLhash);
  }, 300);

  $('.js-btn-nav').on('click', function (e) {
    e.preventDefault();
    if (APP.noTap) return false;
    APP.noTap = true;
    if ($(this).hasClass('is-open')) {
      $(this).removeClass('is-open');
      $('body').removeClass('is-block');
      $('.js-nav').stop(true, true).fadeOut(function() {
        APP.noTap = false;
      });
    } else {
      $(this).addClass('is-open');
      $('body').addClass('is-block');
      $('.js-nav').stop(true, true).fadeIn(function() {
        APP.noTap = false;
      });
    }
    return false;
  });

  APP.slickObj = $('.js-slider').slick({
    autoplay: true,
    arrows: false,
    dots: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    autoplaySpeed: 4000,
    speed: 2000,
    fade: true
  });

  $('.js-acc-btn').on('click', function (e) {
    e.preventDefault();
    if (APP.noTap) return false;
    APP.noTap = true;
    $(this).toggleClass('is-open');
    $(this).next('.js-acc-content').stop(true, true).slideToggle(function () {
      APP.noTap = false;
    });
    return false
  });

  AOS.init({
    offset: 150,
    duration: 1000,
    delay: 0,
    once: true
  });

});

/* FUCTION
--------------------------------------------------*/

// SCROLL
const winScroll = function () {
  // PAGETOP
  // if (!APP.isSP) {
  //   if ((APP.scrollTop + APP.wh) < $('footer').offset().top) {
  //     $('#pagetop').addClass('is-fixed');
  //   } else {
  //     $('#pagetop').removeClass('is-fixed');
  //   }
  //   if (APP.scrollTop < 10) {
  //     $('#pagetop').fadeOut();
  //   } else {
  //     $('#pagetop').fadeIn();
  //   }
  // }

  // let _top = $(window).scrollTop();
  // let scrolled = _top + Math.floor(window.parent.innerHeight * 0.9);
  // $.each($('.js-anim'), function(){
  //   if(scrolled > parseInt($(this).offset().top)){
  //     if (!$(this).hasClass('inview') ) {
  //       $(this).addClass('inview');
  //     }
  //   }
  // });
};

/* RESIZE
--------------------------------------------------*/
APP.resizeFlag = false;
$(window).on('resize orientationchange', function() {
  APP.ww = window.innerWidth ? window.innerWidth: $(window).width();
  APP.wh = window.innerHeight ? window.innerHeight: $(window).height();
  APP.scrollTop = $(window).scrollTop();
  APP.scrollBottom = APP.scrollTop + APP.wh;
  if(!APP.resizeFlag) {
    APP.resizeFlag = true;
    setTimeout(function(){
      if(APP.ww <= APP.spBreakPoint) {
        APP.isSP = true;
        if(APP.cww != APP.ww){
          APP.cww = APP.ww;
          // isSP
        }
      }else {
        APP.isSP = false;
        APP.cww = APP.ww;
        // isPC
      }
      if (_ua.iOS && APP.cwh != APP.wh) {
        if (APP.cwh < APP.wh) {
          $('body').removeClass('is-menu');
        } else {
          $('body').addClass('is-menu');
        }
        APP.cwh = APP.wh;
      }
      APP.resizeFlag = false;
      return APP.resizeFlag;
    },100);
  }
}).trigger('resize');

/* SCROLL
--------------------------------------------------*/
$(window).on('scroll',function(){
  APP.scrollTop = $(window).scrollTop();
  winScroll();
}).trigger('scroll');